/*
 * Courses.less
 * -----------------------------------------------
*/
.course-details {
    position: relative;
}
.course-details-bottom ul {
  margin-left: 2px;
}
.course-details-bottom ul li {
  color: #fff;
  font-size: 14px;
  padding: 6px 18px;
  text-align: center;
}
.list-inline span {
  display: list-item;
}
.course-details-bottom ul li:nth-child(1) {
  background: #88186E none repeat scroll 0 0;
}
.course-details-bottom ul li:nth-child(2) {
  background: #66CC99 none repeat scroll 0 0;
}
.course-details-bottom ul li:nth-child(3) {
  background: #F39C12 none repeat scroll 0 0;
}
.course-details-box {
  background: rgba(0, 0, 0, 0) url("../images/bg/bg7.jpg") repeat scroll center center / cover ;
}
.course-details .price-tag {
  background: rgba(236, 119, 75, 0.9) none repeat scroll 0 0;
  border-radius: 12px 0 0;
  color: #fff;
  font-family: fredericka the great;
  letter-spacing: 1px;
  line-height: 24px;
  padding: 2px 12px;
  position: absolute;
  right: 0;
  top: 10px;
}