/*
 * Shortcode: buttons.less
 * -----------------------------------------------
*/

/* -------- Btn-Default ---------- */
.btn-default {
  .button-variant(@btn-default-color; @btn-default-bg; @btn-default-border);
}
.btn-default {
  &:hover {
  	background-color: @gray-lighter;
  	border-color: @gray-lighter;
  }
}

/* -------- Btn-Border ---------- */
.btn-border {
	.button-variant(@btn-border-color; @btn-border-bg; @btn-border-border);
}
.btn-border {
	border-width: 2px;
}
.btn-border:hover,
.btn-border:active,
.btn-border:focus {
  background-color: @btn-border-border;
  border-color: @btn-border-border;
  color: @white-base;
}

/* -------- Btn-Dark ---------- */
.btn-dark {
 	.button-variant(@btn-dark-color; @btn-dark-bg; @btn-dark-border);
}

/* -------- Btn-Gray ---------- */
.btn-gray {
 	.button-variant(@btn-gray-color; @gray-lighter; @gray-lighter);
}
.btn-circled {
	border-radius: 25px;
}
.btn-flat {
	border-radius: 0;
}

/* -------- Btn-Default ---------- */
.btn {
	font-size: 13px;
	padding: 8px 22px;
}

/* -------- Btn-xs ---------- */
.btn-xs {
	font-size: 11px;
	padding: 3px 14px;
}

/* -------- Btn-sm ---------- */
.btn-sm {
	font-size: 12px;
	padding: 5px 17px;
}

/* -------- Btn-lg ---------- */
.btn-lg {
	font-size: 14px;
	padding: 10px 26px;
}

/* -------- Btn-xl ---------- */
.btn-xl {
	font-size: 16px;
	padding: 13px 32px;
}

/* -------- Btn-transparent ---------- */
.btn-transparent {
	background-color: transparent;
	color: @white-base;
}


/* btn read more*/
.btn-read-more {
  padding: 0;
}
.btn-read-more::after {
  display: inline-block;
  content: "\f101";
  font-family: fontawesome;
  font-size: 16px;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  margin-left: 5px;
  position: relative;
  text-shadow: none;
  text-transform: none;
  top: 2px;
  .transition(all 0.4s ease 0s);
}