/*
 * Shortcode: progress-bar.less
 * -----------------------------------------------
*/
.progress-item {
	margin-top: 20px;
	.progress {
		background-color: @white-f8;
		box-shadow: 0 0 1px fade(@black, 40%) inset;
		border-radius: 0;
		height: 14px;
		margin-bottom: 15px;
		overflow: visible;
	}

	.progress-bar {
        font-size: 12px;
        line-height: 12px;
        padding-left: 10px;
        position: relative;
        text-align: left;
        width: 0;
		.transition(all 1s ease 0s);
		.percent {
			background: #1dabb8 none repeat scroll 0 0;
            border-radius: 0;
            padding: 5px 8px;
            position: absolute;
            right: -15px;
            top: -4px;
		}
	}

	&.progress-white {
		.progress {
			padding: 2px;
		}
	}

	&.style2 {
		.progress {
			height: 25px;
		}

		.progress-bar {
              span {
              color: #fff !important;
              right: 0;
              top: 2px;
            }
			.percent {
				background: none;
				color: @black-555;
				font-size: 14px;
			}
		}
	}
}


