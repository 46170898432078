/*
 * event.less
 * -----------------------------------------------
*/

.event .event-content .media-heading a {
	.transition(all 0.4s ease 0s);
}
.event .event-content ul li {
	font-size: 12px;
	line-height: 1;
	margin-top: 10px;
	margin-bottom: 10px;
}
.event .event-date ul li {
	line-height: 1;
}
.event .event-date ul li:first-child {
	margin-bottom: 10px;
}

/* -------- event Style ---------- */
.event-list .thumb {
    position: relative;
    overflow: hidden;
}
.event-list .thumb img {
    transition: all 500ms ease 0s;
}
.event-list:hover .thumb img {
    transform: scale(1.1);
}
.event-list .thumb .overlay-donate-now {
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: -50px;
    transition: all 400ms ease 0s;
}
.event-list:hover .thumb .overlay-donate-now {
    top: 10%;
}
.event-list .thumb .entry-date {
    bottom: 0;
    position: absolute;
    right: -44px;
    .transition(all 0.3s ease 0s);
}
.event-list:hover .thumb .entry-date {
    right: 0;
}

/* -------- Event-Table ---------- */
.table-schedule {
	thead tr th {
		color: #fff;
		font-size: 16px;
		padding: 15px 25px;
		text-transform: uppercase;
	}
	> tbody > tr {
		background-color: #f3f3f3;
		&:nth-child(2n+1) {
			background-color: #f9f9f9;
		}
	}
	tbody tr td {
		border-top: 1px solid #f3f3f3;
		color: #666;
		font-weight: 400;
		padding: 15px 25px;
		
		.speaker-thumb {
			float: left;
			margin-right: 15px;
			margin-top: 5px;
			width: 55px;
		}
		.title {
			font-size: 15px;
			margin-bottom: 0;
			margin-top: 3px;
		}
		.name {
			font-size: 13px;
			margin: 0;
		}
		strong {
			color: #666;
			font-weight: 700;
		}
	}
	.toggle-content {
		cursor: pointer;
	}
	.session-details {
		display: none;
	}
}
.upcoming-event-right {
  margin-left: 90px;
}
/* Custom, iPhone Retina */
@media only screen and (max-width : 360px) {
    .event-date.sm-custom-style {
      background: rgba(0, 0, 0, 0) none repeat scroll 0 0 !important;
      padding-top: 0 !important;
    }
    .event-date.sm-custom-style li {
      color: #555 !important;
    }
    .event .event-content .media-heading {
      font-size: 14px !important;
      margin-top: 5px;
    }
    .event .event-content ul li {
      margin-bottom: 5px;
      margin-top: 5px;
    }
}