/*
 * overlays.less
 * -----------------------------------------------
*/
.layer-overlay {
	position: relative;

	&::before {
		background: fade(@black-111, 50%) none repeat scroll 0 0;
		content: " ";
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: @zindex-layer-overlay-before;
	}
	&.overlay-light {
		&::before {
			background-color: fade(@white-base, 40%);
		}
	}
	&.overlay-lighter {
		&::before {
			background-color: fade(@white-base, 30%);
		}
	}
	&.overlay-deep {
		&::before {
			background-color: fade(@white-base, 90%);
		}
	}
	/* overlay-dark */
	&.overlay-dark {
		&::before {
			background-color: fade(@black-111, 85%);
		}
	}
	&.overlay-dark-1 {
		&::before {
			background-color: fade(@black-111, 10%);
		}
	}
	&.overlay-dark-2 {
		&::before {
			background-color: fade(@black-111, 20%);
		}
	}
	&.overlay-dark-3 {
		&::before {
			background-color: fade(@black-111, 30%);
		}
	}
	&.overlay-dark-4 {
		&::before {
			background-color: fade(@black-111, 40%);
		}
	}
	&.overlay-dark-5 {
		&::before {
			background-color: fade(@black-111, 50%);
		}
	}
	&.overlay-dark-6 {
		&::before {
			background-color: fade(@black-111, 60%);
		}
	}
	&.overlay-dark-7 {
		&::before {
			background-color: fade(@black-111, 70%);
		}
	}
	&.overlay-dark-8 {
		&::before {
			background-color: fade(@black-111, 80%);
		}
	}
	&.overlay-dark-9 {
		&::before {
			background-color: fade(@black-111, 90%);
		}
	}
	
	/* overlay-white */
	&.overlay-white {
		&::before {
			background-color: fade(@white-base, 40%);
		}
	}
	&.overlay-white-1 {
		&::before {
			background-color: fade(@white-base, 10%);
		}
	}
	&.overlay-white-2 {
		&::before {
			background-color: fade(@white-base, 20%);
		}
	}
	&.overlay-white-3 {
		&::before {
			background-color: fade(@white-base, 30%);
		}
	}
	&.overlay-white-4 {
		&::before {
			background-color: fade(@white-base, 40%);
		}
	}
	&.overlay-white-5 {
		&::before {
			background-color: fade(@white-base, 50%);
		}
	}
	&.overlay-white-6 {
		&::before {
			background-color: fade(@white-base, 60%);
		}
	}
	&.overlay-white-7 {
		&::before {
			background-color: fade(@white-base, 70%);
		}
	}
	&.overlay-white-8 {
		&::before {
			background-color: fade(@white-base, 80%);
		}
	}
	&.overlay-white-9 {
		&::before {
			background-color: fade(@white-base, 90%);
		}
	}
	
	&.layer-pattern {
		&::before {
			background-image: url(../images/pattern1.png);
			background-color: fade(@black, 60%);
		}
	}
	&.layer-pattern2 {
		&::before {
			background-image: url(../images/pattern2.png);
			background-color: fade(@black, 80%);
		}
	}
	&.layer-pattern3 {
		&::before {
			background-image: url(../images/pattern3.png);
			background-color: fade(@black, 80%);
		}
	}
	&.maximage-layer-overlay {
		&::before {
			z-index: @zindex-maximage-layer-overlay-before;
		}
	}
	&.overlay-blue {
		&::before {
			background-color: rgba(1,162,208,0.9);
		}
	}
	&.overlay-blue-light {
		&::before {
			background-color: rgba(1,162,208,0.45);
		}
	}
	&.overlay-pink {
		&::before {
			background-color: rgba(235,110,142,0.9);
		}
	}
	&.overlay-pink-light {
		&::before {
			background-color: rgba(235,110,142,0.45);
		}
	}
	&.overlay-brown {
		&::before {
			background-color: rgba(60,171,111,0.9);
		}
	}
	&.overlay-brown-light {
		&::before {
			background-color: rgba(60,171,111,0.45);
		}
	}
	&.overlay-yellow {
		&::before {
			background-color: rgba(255,187,34,0.9);
		}
	}
	&.overlay-yellow-light {
		&::before {
			background-color: rgba(255,187,34,0.45);
		}
	}
	&.overlay-green {
		&::before {
			background-color: rgba(16,196,92,0.9);
		}
	}
	&.overlay-green-light {
		&::before {
			background-color: rgba(16,196,92,0.45);
		}
	}

	&.overlay-theme-color-1 {
		&::before {
			background-color: rgba(173,216,230,.9);
		}
	}

	&.overlay-theme-color-2 {
		&::before {
			background-color: rgba(230,173,188,0.9);
		}
	}

	&.overlay-theme-color-3 {
		&::before {
			background-color: rgba(230,216,173,0.9);
		}
	}

}

.layer-overlay-gradient {
	position: relative;

	&::before {
		background: -webkit-linear-gradient(45deg, rgba(188, 45, 8, 0.1), rgba(5, 5, 5, 0.6));
		content: " ";
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: @zindex-layer-overlay-before;
	}	
	/* overlay-dark */
	&.overlay-dark {
		&::before {
			background-color: fade(@black-111, 40%);
		}
	}
}
.overlay-gradient:before {
  background: rgba(0, 0, 0, 0.4) -webkit-linear-gradient(right top , rgba(224, 45, 100, 0.5), rgba(116, 117, 170, 0.4), rgba(35, 170, 224, 0.6)) repeat scroll 0 0 !important;
  background: rgba(0, 0, 0, 0.4) -moz-linear-gradient(right top , rgba(224, 45, 100, 0.5), rgba(116, 117, 170, 0.4), rgba(35, 170, 224, 0.6)) repeat scroll 0 0 !important;
  background: rgba(0, 0, 0, 0.4) -ms-linear-gradient(right top , rgba(224, 45, 100, 0.5), rgba(116, 117, 170, 0.4), rgba(35, 170, 224, 0.6)) repeat scroll 0 0 !important;
  background: rgba(0, 0, 0, 0.4) -o-linear-gradient(right top , rgba(224, 45, 100, 0.5), rgba(116, 117, 170, 0.4), rgba(35, 170, 224, 0.6)) repeat scroll 0 0 !important;
}
.overlay-gradient-light:before {
  background: rgba(0, 0, 0, 0.0) -webkit-linear-gradient(right top , rgba(224, 45, 100, 0.2), rgba(116, 117, 170, 0.2), rgba(35, 170, 224, 0.2)) repeat scroll 0 0 !important;
  background: rgba(0, 0, 0, 0.0) -moz-linear-gradient(right top , rgba(224, 45, 100, 0.2), rgba(116, 117, 170, 0.2), rgba(35, 170, 224, 0.2)) repeat scroll 0 0 !important;
  background: rgba(0, 0, 0, 0.0) -ms-linear-gradient(right top , rgba(224, 45, 100, 0.2), rgba(116, 117, 170, 0.2), rgba(35, 170, 224, 0.2)) repeat scroll 0 0 !important;
  background: rgba(0, 0, 0, 0.0) -o-linear-gradient(right top , rgba(224, 45, 100, 0.2), rgba(116, 117, 170, 0.2), rgba(35, 170, 224, 0.2)) repeat scroll 0 0 !important;
}
.half-divider .overlay-theme-color-1,.half-divider .overlay-theme-color-2 ,.half-divider .overlay-theme-color-3 {
  content: "";
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
}
