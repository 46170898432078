 @charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:        childhaven
Version:        2.0
Last change:    02.20.2016
Primary use:    Kids School & Kinder Garten HTML5 Template
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/

// Import bootstrap variables and mixins for reference
@import (reference) "less-bootstrap/variables.less";
@import (reference) "less-bootstrap/mixins.less";

//fonts
@import "../css/font-awesome.min.css";
@import "../css/font-awesome-animation.min.css";
@import "../css/pe-icon-7-stroke.css";
/* @import "../css/elegant-icons.css"; */
/* @import "../css/stroke-gap-icons.css"; */
@import "../css/flaticon-set-charity.css";
@import "../css/utility-classes.css";
@import url(https://fonts.googleapis.com/css?family=Lora:400,700|Dosis:200,300,400,500,600,700,800|Bitter:400,700|Chewy);

 
// Table of contents
@import "less-childhaven/table-of-content.less";

 
// Initialize Variables
@import "less-childhaven/variables.less";
@import "less-childhaven/mixins.less";
// Typography
@import "less-childhaven/typography.less";

// Common Styles
@import "less-childhaven/common.less";
@import "less-childhaven/extra.less";
@import "less-childhaven/overlay.less";

// Header
@import "less-childhaven/header.less";

// Nav
@import "less-childhaven/nav.less";

// Content Blocks
@import "less-childhaven/topbar.less";
@import "less-childhaven/inner-header-title.less";
@import "less-childhaven/vertical-nav.less";
@import "less-childhaven/menu-full-page.less";
@import "less-childhaven/boxed-layout.less";
@import "less-childhaven/form.less";
@import "less-childhaven/side-push-panel.less";
@import "less-childhaven/box-hover-effect.less";
@import "less-childhaven/gallery-isotope.less";
@import "less-childhaven/sliders.less";
@import "less-childhaven/home.less";
@import "less-childhaven/about.less";
@import "less-childhaven/course.less";
@import "less-childhaven/volunteer.less";
@import "less-childhaven/contact.less";
@import "less-childhaven/event.less";
@import "less-childhaven/practice-area.less";
@import "less-childhaven/services.less";
@import "less-childhaven/job.less";
@import "less-childhaven/shop.less";
@import "less-childhaven/blog.less";

// Shortcodes
@import "less-childhaven/shortcodes.less";


// Widgets
@import "less-childhaven/widgets.less";


// Footer
@import "less-childhaven/footer.less";