/*
 * gallery-isotope.less
 * -----------------------------------------------
*/

/* -------- Portfolio Isotop Filter ---------- */
.portfolio-filter {
	margin-bottom: 30px;
	text-align: center;
	a {
		color: @black-555;
		display: inline-block;
		float: none;
		font-size: 16px;
		font-weight: 400;
		line-height: normal;
		margin-right: 5px;
		outline: medium none;
		padding: 5px 15px;
		&.active ,
		&:hover {
			color: @black-111;
			background-color: @white-f5;
		}
	}
}

/* -------- Portfolio Isotop Gallery ---------- */
.gallery-isotope {
	/*gutter*/
	&.gutter .gallery-item {
	    padding-right: 5px;
	    padding-bottom: 5px;
	}
	&.gutter-small .gallery-item {
	    padding-right: 2px;
	    padding-bottom: 2px;
	}
	&.gutter-5 .gallery-item {
	    padding-right: 5px;
	    padding-bottom: 5px;
	}
	&.gutter-10 .gallery-item {
	    padding-right: 10px;
	    padding-bottom: 10px;
	}
	&.gutter-20 .gallery-item {
	    padding-right: 20px;
	    padding-bottom: 20px;
	}
	&.gutter-30 .gallery-item {
	    padding-right: 30px;
	    padding-bottom: 30px;
	}
	&.gutter-40 .gallery-item {
	    padding-right: 40px;
	    padding-bottom: 40px;
	}
	&.gutter-50 .gallery-item {
	    padding-right: 50px;
	    padding-bottom: 50px;
	}
	&.gutter-60 .gallery-item {
	    padding-right: 60px;
	    padding-bottom: 60px;
	}

	/* by default 4 grids */
	.gallery-item {
		width: 25%;
		&.wide {
			width: 50%;
		}
	}

	/*portfolio 2 grids*/
	&.grid-1 .gallery-item {
	    width: 100%;
		&.wide {
			width: 100%;
		}
	}

	/*portfolio 2 grids*/
	&.grid-2 .gallery-item {
	    width: 50%;
		&.wide {
			width: 100%;
		}
	}

	/*portfolio 3 grids*/
	&.grid-3 .gallery-item {
	    width: 33.333%;
		&.wide {
			width: 66.6667%;
		}
	}

	/*portfolio 4 grids*/
	&.grid-4 .gallery-item {
	    width: 25%;
		&.wide {
			width: 50%;
		}
	}

	/*portfolio 5 grids*/
	&.grid-5 .gallery-item {
	    width: 20%;
		&.wide {
			width: 40%;
		}
	}

	/*portfolio 6 grids*/
	&.grid-6 .gallery-item {
	    width: 16.666666667%;
		&.wide {
			width: 33.333%;
		}
	}

	/*portfolio 7 grids*/
	&.grid-7 .gallery-item {
	    width: 14.2857143%;
		&.wide {
			width: 28.5714286%;
		}
	}

	/*portfolio 8 grids*/
	&.grid-8 .gallery-item {
	    width: 12.5%;
		&.wide {
			width: 25%;
		}
	}

	/*portfolio 9 grids*/
	&.grid-9 .gallery-item {
	    width: 11.11%;
		&.wide {
			width: 22.22%;
		}
	}

	/*portfolio 10 grids*/
	&.grid-10 .gallery-item {
	    width: 10%;
		&.wide {
			width: 20%;
		}
	}
}

/* -------- Portfolio Isotop Item Media Query ---------- */
.gallery-isotope {
	.gallery-item {
		@media only screen and (max-width: 991px) {
			width: 33.333% !important;
			&.wide {
				width: 66.6667% !important;
			}
		}
		@media only screen and (max-width: 767px) {
			width: 50% !important;
			&.wide {
				width: 100% !important;
			}
		}
		@media only screen and (max-width: 479px) {
			width: 100% !important;
			&.wide {
				width: 100% !important;
			}
		}
	}
}

/* -------- Portfolio Isotop Overlay Effect ---------- */
.gallery-isotope {
	.gallery-item {
		overflow: hidden;
		.thumb {
			position: relative;
			overflow: hidden;
		}

		.hover-link {
			position: absolute;
			top: 0;
			left: 0;
			display: inline-block;
			vertical-align: middle;
			width: 100%;
			height: 100%;
			background: none!important;
			color: transparent!important;
			z-index: 11;
		}

		.overlay-shade {
			position: absolute;
			display: inline-block;
			vertical-align: middle;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 12;
			background-color: #333;
			opacity: 0;
			filter: alpha(opacity=0);
			.transition(opacity .3s ease-in-out);
		}

		&:hover .overlay-shade {
			opacity: 0.9;
			filter: alpha(opacity=90);
		}

		.icons-holder {
			position: absolute;
			top: 0%;
			left: 50%;
			display: inline-block;
			width: auto;
			height: 36px;
			vertical-align: middle;
			z-index: 13;
			.transition(all .3s ease-in-out);

			.icons-holder-inner {
				position: relative;
				display: inline-block;
				vertical-align: middle;
				margin: -18px 0 0 -50%;

				a {
					opacity: 0;
					filter: alpha(opacity=0);
					.transition(all .3s ease-in-out);
				}
			}
		}

		&:hover .icons-holder {
			top: 50%;
			.icons-holder-inner {
				a {
					opacity: 1;
					filter: alpha(opacity=1);
				}
			}
		}

		.portfolio-description {
		 	padding: 15px 0;

		 	.title {
			 	margin: 0;
			 	a {
					color: @headings-color;
				}
			}
		}

		.text-holder {
		 	box-sizing: border-box;
			display: block;
			height: 100%;
			left: 0;
			opacity: 0;
			overflow: hidden;
			padding: 25px 30px 60px;
			position: absolute;
			top: 0;
			.transition(opacity 0.3s ease-in-out 0s);
			width: 100%;
			z-index: 13;

		 	.title {
		 		color: #fff;
			}
		}

		&:hover .text-holder {
			opacity: 1;
		}
	}
	//flex slider gallery control nav
	.flex-control-nav {
		z-index: 111;
	}
}
.gallery-block {
 border-radius: 12px;
  overflow: hidden;
  position: relative;
}
.gallery-block .overlay-shade {
  background-color: rgba(29, 175, 185, 0.8);
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.3s ease-in-out 0s;
  width: 100%;
}
.gallery-block:hover .overlay-shade {
  opacity: 0.9;
}
.gallery-block .icons-holder {
  display: inline-block;
  height: 36px;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: all 0.3s ease-in-out 0s;
  vertical-align: middle;
  width: auto;
}
.gallery-block:hover .icons-holder {
  opacity: 1;
  top: 50%;
}
.gallery-block .icons-holder .icons-holder-inner {
  display: inline-block;
  margin: -18px 0 0 -50%;
  position: relative;
  vertical-align: middle;
}
.gallery-icon a {
  background: rgba(0, 0, 0, 0.3) none repeat scroll 0 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0;
  color: #fff;
  display: inline-block;
  font-size: 28px;
  height: 48px;
  line-height: 50px;
  text-align: center;
  width: 48px;
}